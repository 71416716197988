<template>
  <div>
    <Breadcrumbs page_title="article" :items="breadcrumbs" />
    <v-card class="mx-3 my-5 rounded-lg">
      <template>
        <v-container fluid>
          <v-row class="d-flex">
            <v-col cols="6" lg="5" md="6" sm="12" class="pa-5">
              <v-img
                :src="
                  articleItems.picturebackground
                    ? articleItems.picturebackground
                    : ''
                "
                class="orgImage"
              ></v-img>
            </v-col>
            <v-col cols="6" lg="7" md="6" sm="12" class="pa-5">
              <v-card-title class="Casetext">{{
                articleItems.contentName
              }}</v-card-title>
              <v-card-subtitle
                style="font-size: 18px; opacity: 0.8; line-height: 25px"
                class="mt-3"
              >
                <div>
                  <p class="breaktext">{{ articleItems.wordDescription }}</p>
                </div>
              </v-card-subtitle>
              <v-card-subtitle
                style="font-size: 18px; opacity: 0.8; line-height: 20px"
                class="mt-3"
              >
                <div>
                  <p class="breaktext">{{ articleItems.wordContentText }}</p>
                </div>
              </v-card-subtitle>
              <v-row class="mt-10" cols="6" v-if="hasLink">
                <v-col md="2" lg="2">
                  <v-card-title>{{ $t("link") }}</v-card-title>
                </v-col>
                <v-col cols="11" md="10" lg="10" class="pl-1"
                  ><p
                    @click="clickLink(articleItems.link)"
                    style="
                      color: blue;
                      font-size: 12px;
                      opacity: 0.8;
                      padding-top: 25px;
                      cursor: pointer;
                     
                    "
                    class="mb-0 textlink text-decoration-underline"
                  >
                    {{ cutText(articleItems.link) }}
                  </p></v-col
                >
              </v-row>
              <v-card-actions class="ml-8">
                <v-col cols="5" class="d-flex justify-end">
                  <v-btn
                    v-if="
                      articleItems.pdfUrl != null && articleItems.pdfUrl != ''
                    "
                    depressed
                    color="#A6CC39"
                    @click="
                      clickLink(articleItems.pdfUrl) &&
                        articleItems.pdfUrl.trim().length > 0
                    "
                    class="login-phone text-capitalize downlodebtn"
                    download
                    >{{ $t("DownloadPDF") }}</v-btn
                  >
                </v-col>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {
    // SuccessDialog
  },

  data() {
    return {
      search: "",
      //article: null,
      articleItems: "",
      dialogm1: "",
      dialog: false,
      id: null,
      hasLink: false,
      maxlength: 20,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          sidebar_tilte: "home",
        },
        {
          text: "store",
          href: "/home/store",
        },
        {
          text: "article",
          href: "/home/article",
        },
      ];
    },
  },
  watch: {
    "articleItems.link"(newValue) {
      this.hasLink = newValue != null && newValue.trim().length > 0;
    },
  },
  mounted() {
    this.getArticle();
  },
  methods: {
    cutText(v) {
      if (v.length <= this.maxlength) {
        return v;
      } else {
        return v.substring(0, this.maxlength) + "...";
      }
    },
    async getArticle() {
      let self = this;
      self.article = self.$route.query.data;
      self.loading = true;
      await axios
        .get(`${self.web_url}Contents/GetWordContentForView?ID=` + self.article)
        .then(function (response) {
          self.articleItems = response.data.data;
          self.loading = false;
        })
        .catch(function (res) {alert(res);});
    },
    dialogopen() {
      this.dialog = true;
    },
    clickLink(link) {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "http://" + link;
      } //link wont open if it start with www.example.com

      window.open(link, "_blank");
    },
  },
};
</script>

<style>
@media (min-width: 1440px) and (max-width: 2560px) {
  .lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .storecol {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
  .content {
    width: 45%;
    max-width: 45%;
    flex-basis: 45%;
  }
}
</style>

<style scoped>
.breaktext {
  white-space: pre-line;
}
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.downlodebtn {
  color: white;
}
.cardTitle {
  position: relative;
  color: #fff;
  font-size: 23px;
  justify-content: center;
  top: 40px;
}
.orgImage {
  width: 600px;
  height: 375px;
  border-radius: 20px !important;
}
.icons {
  position: absolute;
  bottom: -13px;
  right: -10px;
}
.v-card {
  border-radius: 20px;
}
.title {
  margin-bottom: 20px;
  font-size: 20px !important;
  color: #424242;
}
.store_btn {
  top: -20px !important;
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.store_btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.store_btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.store_btn:hover {
  transition: 0.25s;
}
.store_btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
.numberofview {
  margin-right: 10px !important;
  display: flex !important;
  justify-content: end;
  color: #424242;
  font-size: 18px !important;
}
.Casetext {
  color: rgb(66, 66, 66);
  font-weight: bold;
  font-size: 23px;
}
</style>
